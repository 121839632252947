<template>
  <div @keydown="handleSearch" @focusout="handleFocusOut" class="list-box" :class="{ empty: required && !value }">
    <span @click="value = null" v-if="remove && value && !disabled" class="list-box-remove"><Icon icon="material-symbols-light:close-small-outline" /></span>
    <div class="d-flex gap-1">
      <label v-if="label" class="form-label mb-0">{{ label }}</label>
      <Tooltip v-if="info" :info="info" />
    </div>
    <Listbox v-slot="{ open }" v-model="value" :multiple="multiple" :by="by" :disabled="disabled">
      <div v-if="updateOpenState(open)" />
      <ListboxButton v-if="!value && label && !placeholder || !value && placeholder" class="select-placeholder">{{ placeholder || label }}</ListboxButton>
      <ListboxButton v-else-if="typeof value !== 'object'">
        <template v-if="multiple && value">
          <span v-if="value.length === options.length">Alle ausgewählt</span>
          <span v-else-if="value.length === 0" class="select-placeholder"><template v-if="placeholder">{{ placeholder }}</template><template v-else>-</template></span>
          <span v-else>{{ value.join(', ') }}</span>
        </template>

        <template v-else>{{ value }}</template>
      </ListboxButton>
      <ListboxButton v-else>
        <template v-if="multiple">
          <span v-if="value && value.length === options.length && options.length > 3">Alle ausgewählt</span>
          <span v-else-if="value && value.length === 0" class="select-placeholder"><template v-if="placeholder">{{ placeholder }}</template><template v-else>-</template></span>
          <span v-else-if="value && value.length > 3">{{ value.length }} ausgewählt</span>
          <span v-else>{{ value.map((_value) => Object.byString(_value, text)).join(', ') }}</span>
        </template>
        <template v-else-if="value">{{ Object.byString(value, text) }}</template>
      </ListboxButton>
      <ListboxOptions>
        <div class="p-2">
          <Input v-model="searchQuery" placeholder="Suche" />
        </div>
        <div v-if="selectAllButton" class="d-flex justify-content-end p-2 f-small">
          <span @click="selectAll" class="mb-0 cursor-pointer bold">
            <template v-if="value && value.length !== options.length">Alle auswählen</template>
            <template v-else>Alle abwählen</template>
          </span>
        </div>
        <template v-if="options.length > 0">
          <template v-for="option in filteredOptions" :key="option.id">
            <ListboxOption v-if="typeof option !== 'object'" :value="option" :disabled="disable && disable === option" data-v-inspector>{{ option }}</ListboxOption>
            <ListboxOption v-else :value="option" :disabled="disable && disable === Object.byString(option, text)" data-v-inspector>{{ Object.byString(option, text) }}</ListboxOption>
          </template>
        </template>
        <div v-else class="p-2 text-center">Keine Auswahl verfügbar</div>
      </ListboxOptions>
    </Listbox>
  </div>
</template>

<script setup>
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { useAuthStore } from "~/stores/AuthStore";

const props = defineProps([ "info", "modelValue", "options", "label", "text", "returnValue", "multiple", "disabled", "disable", "required", "placeholder", "remove", "ignorePermission", "by", "selectAllButton", "searchable" ]);
const emit = defineEmits([ "update:modelValue", "change", "close" ]);

const label = ref(props.label);
const placeholder = ref(props.placeholder);
const disabled = ref(props.disabled);
const by = ref(props.by);
const isOpen = ref(false);
const searchQuery = ref("");

const filteredOptions = computed(() => {
  if (!searchQuery.value) return props.options;
  return props.options.filter(option => option[props.text].toLowerCase().includes(searchQuery.value.toLowerCase()));
});

function handleSearch(event) {
  event.preventDefault();
  if (event.key === "Backspace") {
    searchQuery.value = searchQuery.value.slice(0, -1);
  } else if (event.key.length === 1) {
    searchQuery.value += event.key;
  }
}

function resetSearch() {
  searchQuery.value = "";
}

function handleFocusOut(event) {
  if ((event.relatedTarget && (event.relatedTarget.tagName === "LI" || event.relatedTarget.tagName === "BUTTON")) || event.relatedTarget === null) {
    return; // Don't reset search if focus moves to an option
  }
  resetSearch(); // Reset search when focus leaves
}

if (!by.value) by.value = "id";

if (props.required) {
  if (label.value) label.value += "*";
  if (placeholder.value) placeholder.value += "*";
}

function updateOpenState(_open) {
  if (isOpen.value && !_open) emit("close");
  isOpen.value = _open;
  return true;
}

// Berechtigung überprüfen
const route = useRoute();
const authStore = useAuthStore();
if (route.meta.permission && !props.ignorePermission && !authStore.hasPermission([ route.meta.permission ])) disabled.value = true;

const convertedValue = computed(() => {
  if (props.modelValue === null) return null;
  if (props.returnValue) {
    return props.options.find(
      _option => String(_option[props.returnValue]) === String(props.modelValue),
    );
  }

  return props.modelValue;
});

const value = computed({
  get() {
    return convertedValue.value;
  },
  set(value) {
    emit("update:modelValue", props.returnValue && value !== null ? value[props.returnValue] : value);
  },
});

watch(value, (newValue, oldValue) => {
  if (props.returnValue && oldValue && newValue) {
    if (newValue[props.returnValue] !== oldValue[props.returnValue]) emit("change", newValue.value);
  } else emit("change", newValue?.value);
});

function selectAll() {
  if (value.value.length === props.options.length) value.value = [];
  else {
    value.value = props.options;
  }
}
</script>

<style scoped>
:deep(ul li:focus) {
  outline: none;
  box-shadow: none;
}
:deep(ul:focus) {
  outline: none;
  box-shadow: none;
}
</style>
